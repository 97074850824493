// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  text-align: center;
  background-color: floralwhite;
  height: 100%;
  margin: 0;
  padding: 0;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  flex: 1 1;
  padding: 20px; /* Adjust the padding as needed */
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,aAAa;EACb,SAAO;EACP,aAAa,EAAE,iCAAiC;AAClD","sourcesContent":["html, body {\n  text-align: center;\n  background-color: floralwhite;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n  height: 100vh;\n  flex: 1;\n  padding: 20px; /* Adjust the padding as needed */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
