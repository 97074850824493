// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
  padding-bottom: 60px; 
}
`, "",{"version":3,"sources":["webpack://./src/Column.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".column {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%; \n  padding-bottom: 60px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
